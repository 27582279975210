import React, { useState, useRef, useEffect } from 'react';
import { bubble as Menu } from 'react-burger-menu';
import styled, { keyframes } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-modal';
import RoadmapProgress from 'react-roadmap-progress';
import 'react-roadmap-progress/dist/css/index.css';
import { WIDTH_TABLET, menuStyles, color } from '../util'; 
import { useHistory } from "react-router-dom";
import { HeroCanvas, RarityPage } from './';
// @ts-ignore
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import TatoLogo from '../image/logo.png';
import Twitter from '../image/twitter.png';
import Discord from '../image/discord.png';
import FooterImg from '../image/footer.png';
import Road from '../image/road.svg';
import Star from '../image/star.svg';
import Book from '../image/book.svg';
import Chip from '../image/chip.svg';

import one1 from '../image/demo/1-1.png';
import one2 from '../image/demo/1-2.png';
import one3 from '../image/demo/1-3.png';
import one4 from '../image/demo/1-4.png';
import one5 from '../image/demo/1-5.png';

import two1 from '../image/demo/2-1.png';
import two2 from '../image/demo/2-2.png';
import two3 from '../image/demo/2-3.png';
import two4 from '../image/demo/2-4.png';
import two5 from '../image/demo/2-5.png';

import bio1 from '../image/bio/1.png';
import bio2 from '../image/bio/2.png';
import bio3 from '../image/bio/3.png';
import bio4 from '../image/bio/4.png';

type NormalSiteOptions = {
    readonly setIsOs: any;
};

export function NormalSite({
    setIsOs
}: NormalSiteOptions) {
    Modal.setAppElement('#root');
    const history = useHistory();

    const milestones = [
        {
            title: 'Step 1',
            version: '3.0.1',
            description: (
                <div>
                    <ul>
                        <li>Papertato Collection Design</li>
                        <li>Community Building</li>
                        <li>Site Launch</li>
                        <li>Online Rarity Ranks Published</li>
                        <li>Collect Whitelist Submissions</li>
                    </ul>
                </div>
            ),
            complete: true,
        },
        {
            title: 'Step 2',
            version: '3.0.0',
            description: (
                <div>
                    <ul>
                        <li>Series 1 Whitelist Closed</li>
                        <li>Series 1 Mint Launch on Ethereum Mainnet, <br /> Minting 7000 PaperTatos for 0 ETH</li>
                    </ul>
                </div>
            ),
            complete: false,
        },
        {
            title: 'Step 3',
            version: '2.0.9',
            description: (
                <div>
                    <ul>
                        <li>Series 1 Reveal, trading on Secondary Markets</li>
                        <li>Royalties Invested in Community Development</li>
                    </ul>
                </div>
            ),
            complete: false,
        },
        {
            title: 'Step 4',
            version: '2.0.8',
            description: (
                <div>
                    <ul>
                        <li>Launch Series 2 Mint (Estimated 0.05 ETH/NFT)</li>
                        <li>Early Mint access (24H) for Series 1 Holders</li>
                        <li>Series 1 Holders can decide to mint with the same traits, or re-roll</li>
                        <li>After 24H, the mint is open to everyone <br />Every mint from this point is a traits re-roll</li>
                    </ul>
                </div>
            ),
            complete: false,
        },
        {
            title: 'Step 5',
            version: '2.0.7',
            description: (
                <div>
                    <ul>
                        <li><strong>Donations</strong> (% based on series 2 mint progress)</li>
                        <li>20% Sales - 2 ETH donation to EFF</li>
                        <li>50% Sales - 2 ETH donation to charitable organization, <br />community decides</li>
                        <li>80% Sales - Land is purchased (CryptoVoxels or Sandbox), <br />dedicated and developed for PaperTatos</li>
                        <li>100% Sales - PaperTatos have taken over, time for Real Merch</li>
                    </ul>
                </div>
            ),
            complete: false,
        },
    ];

    const modalStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          height: '548px',
          width: '500px',
        },
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        img: one1,
        text: 'Series 1 PaperTato'
    });

    function handleNftPreviewClick({img, text}: {img: any, text: string}) {
        setModalContent({img, text});
        setModalIsOpen(true);
    }

    function handleOsClick(e: React.SyntheticEvent) {
        e.preventDefault();
        setIsOs(true)
    }

    const roadmapRef = useRef(null);
    const rarityRef = useRef(null);
    const aboutRef = useRef(null);
      
    function scrollToComponent(hash?: string) {
        if (mobileMenuOpen) {
            setMobileMenuOpen(false);
        }
        if (hash === '#roadmap') {
            // @ts-ignore
            roadmapRef.current.scrollIntoView();
            // @ts-ignore
            roadmapRef.current.focus();
        } else if (hash === '#rarity') {
            // @ts-ignore
            rarityRef.current.scrollIntoView();
            // @ts-ignore
            rarityRef.current.focus();
        } else if (hash === '#about') {
            // @ts-ignore
            aboutRef.current.scrollIntoView();
            // @ts-ignore
            aboutRef.current.focus();
        }

        if (!hash) {
            if (window.location.hash === '#roadmap') {
                // @ts-ignore
                roadmapRef.current.scrollIntoView();
                // @ts-ignore
                roadmapRef.current.focus();
            } else if (window.location.hash === '#rarity') {
                // @ts-ignore
                rarityRef.current.scrollIntoView();
                // @ts-ignore
                rarityRef.current.focus();
            } else if (window.location.hash === '#about') {
                // @ts-ignore
                aboutRef.current.scrollIntoView();
                // @ts-ignore
                aboutRef.current.focus();
            }
        }
    }
    
    useEffect( () => scrollToComponent(), [] );

    return (
        <StyledPageWrapper>
            <ReactTooltip place="left" type="dark" effect="float" />
            <Menu 
                styles={menuStyles} 
                isOpen={mobileMenuOpen}
                onOpen={() => setMobileMenuOpen(true)}
                onClose={() => setMobileMenuOpen(false)}
            >
                <a id="roadmap" className="menu-item" href="#roadmap" onClick={() => scrollToComponent('#roadmap')}>Roadmap</a>
                <a id="rarity" className="menu-item" href="#rarity" onClick={() => scrollToComponent('#rarity')}>Rarity</a>
                <a id="about" className="menu-item" href="#about" onClick={() => scrollToComponent('#about')}>About</a>
            </Menu>
            <Hero>
                <HeroCanvas />
                <img className="logo-main" src={TatoLogo} alt="Papertato" />
                <div className="arrow" />
            </Hero>
            <Header>
                <div className="col left">
                    <a id="roadmap" className="desktop menu-item" href="#roadmap" onClick={() => scrollToComponent('#roadmap')}>
                        <span><img src={Road} alt="roadmap" /></span>
                        <span>Roadmap</span>
                    </a>
                    <a id="rarity" className="desktop menu-item" href="#rarity" onClick={() => scrollToComponent('#rarity')}>
                        <span><img src={Star} alt="rarity" /></span>
                        <span>Rarity</span>
                    </a>
                    <a id="about" className="desktop menu-item" href="#about" onClick={() => scrollToComponent('#about')}>
                        <span><img src={Book} alt="about" /></span>
                        <span>About</span>
                    </a>
                    <a id="tato-os" className="menu-item desktop" onClick={(e) => handleOsClick(e)} href="/">
                        <span><img src={Chip} alt="Tato OS" /></span>
                        <span>Tato OS</span>
                    </a>
                </div>
                <div className="col">
                    <AwesomeButton 
                        type="secondary"
                        onPress={() => history.push('/whitelist')}
                    >
                        {/* Connect Wallet */}
                        Join Whitelist
                    </AwesomeButton>
                    <a data-tip="Follow @papertato on Twitter!" className="top social" href="https://twitter.com/papertato" target="_blank" rel="noreferrer">
                        <img className="logo-twitter" src={Twitter} alt="Twitter" />
                    </a>
                    <a data-tip="Join us on Discord!" className="social" href="https://discord.gg/2Z75YRxH4H" target="_blank" rel="noreferrer">
                        <img className="logo-discord" src={Discord} alt="Discord" />
                    </a>
                </div>
            </Header>
            <NftOverview>
                <h3 className="large">
                    PaperTatos are a <span className="light">2-series</span> collection of 14000 potatoes specifically designed to make you question your life choices.
                </h3>
                <h3>
                    The first series is unique, procedurally generated, <br className="nomobile" />and traits are hand crafted. <span className="light">Series 1</span> is also free. <br /><br /><span className="light">Series 2</span> launches PaperTatos into the third dimension of the multiverse, with an early-access mint for <span className="light">Series 1</span> holders.  
                </h3>
            </NftOverview>
            <NftPreview>
                <div data-tip="Series 1 PaperTato">
                    <img src={one1} alt="Series 1 Preview" onClick={() => handleNftPreviewClick({img: one1, text: 'Series 1 PaperTato'})} />
                    <img src={one2} alt="Series 1 Preview" onClick={() => handleNftPreviewClick({img: one2, text: 'Series 1 PaperTato'})} />
                    <img className="mobileThree" src={one3} alt="Series 1 Preview" onClick={() => handleNftPreviewClick({img: one3, text: 'Series 1 PaperTato'})} />
                    <img className="mobileTwo" src={one4} alt="Series 1 Preview" onClick={() => handleNftPreviewClick({img: one4, text: 'Series 1 PaperTato'})} />
                    <img className="mobileOne" src={one5} alt="Series 1 Preview" onClick={() => handleNftPreviewClick({img: one5, text: 'Series 1 PaperTato'})} />
                </div>
                <div data-tip="Series 2 PaperTato">
                    <img className="mobileOne" src={two1} alt="Series 2 Preview" onClick={() => handleNftPreviewClick({img: two1, text: 'Series 2 PaperTato'})} />
                    <img src={two2} alt="Series 2 Preview" onClick={() => handleNftPreviewClick({img: two2, text: 'Series 2 PaperTato'})} />
                    <img className="mobileTwo" src={two3} alt="Series 2 Preview" onClick={() => handleNftPreviewClick({img: two3, text: 'Series 2 PaperTato'})} />
                    <img className="mobileThree" src={two4} alt="Series 2 Preview" onClick={() => handleNftPreviewClick({img: two4, text: 'Series 2 PaperTato'})} />
                    <img src={two5} alt="Series 2 Preview" onClick={() => handleNftPreviewClick({img: two5, text: 'Series 2 PaperTato'})} />
                </div>
            </NftPreview>
            <Roadmap id="roadmap" ref={roadmapRef}>
                <h2 className="title">Roadmap</h2>
                <RoadmapProgress milestones={milestones} />
            </Roadmap>
            <Rarity id="rarity" ref={rarityRef}>
                <h2 className="title">Rarity</h2>
                <div className="chart-wrap"><RarityPage /></div>
                <p>Hover / Touch Cells for More Info</p>
            </Rarity>
            <Team id="about" ref={aboutRef}>
                <h2 className="title">About</h2>
                <p className="unify">
                    We are four French fries making irreverent art for the multiverse. We know these projects are supposed to change the world or be for the common good or whatever, but honestly we just want to make you (and us) laugh. That seems as righteous a goal as any, so we're comfortable with it. We don't take ourselves too seriously and neither do our potatoes.
                </p>
                <div className="grid">
                    <div className="member one">
                        <div className="wrapper">
                            <img src={bio1} alt="about bio" />
                        </div>
                        <p className="name">
                            Petty Lemonade
                        </p>
                        <p className="bio">
                            Designer
                        </p>
                    </div>
                    <div className="member two">
                        <div className="wrapper">
                            <img src={bio2} alt="about bio" />
                        </div>
                        <p className="name">
                            ThunkRaptor
                        </p>
                        <p className="bio">
                            Developer
                        </p>
                    </div>
                    <div className="member three">
                        <div className="wrapper">
                            <img src={bio3} alt="about bio" />
                        </div>
                        <p className="name">
                            Emperor Salad
                        </p>
                        <p className="bio">
                            Cheerleader
                        </p>
                    </div>
                    <div className="member four">
                        <div className="wrapper">
                            <img src={bio4} alt="about bio" />
                        </div>
                        <p className="name">
                            Freshwaters
                        </p>
                        <p className="bio">
                            Licks the butter
                        </p>
                    </div>
                </div>
            </Team>
            <Footer>
                <img src={FooterImg} alt="PaperTato Footer" />
                <p>&#169; {new Date().getFullYear()}. All Rights Reserved.</p>
            </Footer>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={modalStyles}
            >
                <img className="img-modal" src={modalContent?.img} alt={modalContent?.text} />
                <p className="img-modal">{modalContent?.text}</p>
            </Modal>
        </StyledPageWrapper>
    );
}

const StyledPageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box; 

    ul li::before {
        background: transparent !important;
    }

    h2.title {
        font-size: 34px;
        margin: 80px 0 40px;
    }

    & > * {
        &:before, &:after {
            box-sizing: border-box;
        }
        box-sizing: border-box;
    }

    .mobile, .bm-burger-button {
        display: none;
    }
    @media screen and (max-width: ${WIDTH_TABLET}) {
        .mobile, .bm-burger-button {
            display: block;
        }
        .desktop {
            display: none;
        }
    }

    .bm-menu a {
        text-decoration: none;
        color: ${color.offWhite};
        font-weight: 900;
        font-size: 28px;
    }
`;

const Header = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    padding: 0 15px;
    pointer-events: none;
    // background: ${color.offWhite};
    // box-shadow: 0px 0px 12px 5px rgba(94,70,46,0.1);

    @media screen and (max-width: ${WIDTH_TABLET}) {
        a.menu-item {
            .desktop {
                display: none;
            }
        }
        div.col {
            right: 15px !important;
            top: 16px !important;

            &.left {
                display: none;
            }
        } 
    }

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;

    div {
        pointer-events: auto;

        &.col {
            position: absolute;
            right: 34px;
            top: 32px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-content: flex-end;
            align-items: flex-end;

            &.left {
                right: auto;
                left: 34px;
                justify-content: flex-start;
                align-content: flex-start;
                align-items: flex-start;

                a {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-content: space-between;
                    align-items: center;

                    &:hover {
                        color: ${color.red};
                    }

                    span {
                        display: inline-block;
                    }
                    img {
                        height: 20px;
                        width: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }

        a {
            transition: all 0.1s linear 0s; 
            position: relative;
            text-decoration: none;

            &:hover {
                opacity: 0.8;
            }

            &.top {
                margin-top: 14px;
            }

            color: #000;
            font-weight: 900;
            font-size: 24px;
        }

        img {
            max-width: 60px;
        }
    }
`;

const redArrowAnim = keyframes`
    50% { transform: translateX(-50%) translateY(-20px); opacity: 0; }
`;

const Hero = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background: #9ee4e6;

    .arrow {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        height: 0px;
        width: 0px;
        animation-name: ${redArrowAnim};
        animation-duration: 1s;
        animation-iteration-count: infinite;

        &:after {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0px;
            height: 0px;
            content: '';
            border: 26px solid transparent;
            border-top: 18px solid ${color.red};
        }
    }

    .logo-main {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-height: 36vw;
        max-width: 36vw;
        pointer-events: none;
        margin-top: 40px;
    }

    @media screen and (max-width: 1180px) {
        .logo-main {
            max-height: 46vw;
            max-width: 46vw;
        }
    }

    @media screen and (max-width: 920px) {
        .logo-main {
            max-height: 56vw;
            max-width: 56vw;
        }
    }

    @media screen and (max-width: 740px) {
        .logo-main {
            max-height: 66vw;
            max-width: 66vw;
        }
    }

    @media screen and (max-width: 600px) {
        .logo-main {
            max-height: 80vw;
            max-width: 80vw;
        }
    }

    @media screen and (max-width: 500px) {
        .logo-main {
            max-height: 100vw;
            max-width: 100vw;
        }
    }
`;
const NftOverview = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    background: ${color.brownDark};
    color: ${color.offWhite};
    overflow: auto;
    text-align: center;
    
    h3 {
        margin: 0 auto 80px;
        max-width: 716px;
        font-size: 22px;
        &.large {
            margin: 80px auto 30px;
            font-size: 30px;
        }
    }

    span.light {
        color: #fff;
    }

    @media screen and (max-width: 800px) {
        h3 {
            max-width: 580px;
            font-size: 18px;
            &.large {
                font-size: 22px;
            }
        }
    }

    @media screen and (max-width: 660px) {
        h3 {
            padding: 0px 16px;
            max-width: 482px;
            font-size: 16px;
            &.large {
                font-size: 20px;
            }
        }
        .nomobile {
            display: none;
        }
    }
`;
const NftPreview = styled.div`
    width: 100%;

    img {
        transition: opacity 0.1s linear 0s;
        cursor: pointer;
        opacity: 1;

        &:hover {
            opacity: 0.9;
        }
    }

    div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: space-between;
        align-items: center;

        img {
            max-width: 20%;
        }
    }

    @media screen and (max-width: 900px) {
        div img {
            max-width: 25%;
        }
        .mobileOne {
            display: none;
        }
    }

    @media screen and (max-width: 660px) {
        div img {
            max-width: 33.33%;
        }
        .mobileTwo {
            display: none;
        }
    }

    // @media screen and (max-width: 550px) {
    //     div img {
    //         max-width: 50%;
    //     }
    //     .mobileThree {
    //         display: none;
    //     }
    // }
`;
const Roadmap = styled.div`
    font-family: 'Rounded Mplus 1c', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    width: 100%;
    background: ${color.brownDark};
    color: ${color.offWhite};
    
    .milestone__version {
        display: none;
    }
    .milestone__content {
        margin-top: -10px;
        margin-bottom: 4px;
    }
    .roadmap-progress {
        padding-bottom: 80px;
    }

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
`;
const Rarity = styled.div`
    width: 100%;
    color: ${color.brownDark};

    .chart-wrap {
        margin: 0 auto;
        width: 600px;
        max-width: 85%;
        height: 400px;
    }

    h2 {
        text-align: center;
    }

    p {
        font-size: 14px;
        text-align: center;
    }
`;
const Team = styled.div`
    width: 100%;
    color: ${color.brownDark};

    .member {
        .wrapper {
            position: relative;
            &:before {
                content: '';
                width: 80%;
                height: 80%;
                border-radius: 80%;
                z-index: -1;
            }
        }
        &.one {
            .wrapper:before {
                position: absolute;
                top: 0px;
                left: 0px;
                background: #7800ff;
            }
        }
        &.two {
            .wrapper:before {
                position: absolute;
                top: 2px;
                right: 10px;
                background: #0ecbfc;
            }
        }
        &.three {
            .wrapper:before {
                position: absolute;
                top: 10px;
                left: 5px;
                background: #ff8300;
            }
        }
        &.four {
            .wrapper:before {
                position: absolute;
                top: 0px;
                left: 50%;
                transform: translateX(-50%);
                background: #f229cd;
            }
        }
    }

    h2 {
        text-align: center;
    }

    p.unify {
        font-size: 14px;
        max-width: 76%;
        width: 640px;
        text-align: justify;
        margin: auto;
    }

    .grid {
        margin: 48px auto 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: space-between;
        align-items: center;
        max-width: 90%;
        width: 760px;

        .member {
            width: 22%;
            img {
                width: 100%;
            }
            p {
                font-size: 16px;
                &.name {
                    font-weight: 900;
                    margin-bottom: 2px;
                }
                &.bio {
                    font-size: 14px;
                    margin-top: 4px;
                }
            }
        }
    }

    @media screen and (max-width: 640px) {
        .grid {
            flex-direction: column;
            .member {
                width: 52%;
                margin-bottom: 20px;
            }
        }
    }

    @media screen and (max-width: 420px) {
        .grid {
            .member {
                width: 62%;
            }
        }
    }
`;
const Footer = styled.div`
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: ${color.brownDark};
    margin: 40px auto;
    padding-bottom: 20px;

    img {
        width: 400px;
        max-width: 70%;
        margin-top: 16px;
    }
`;