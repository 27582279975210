import { ethers } from 'ethers';

export function validateAddress(addr: string | any) {
    let isAddress = false;
    try {
        ethers.utils.getAddress(addr);
        isAddress = true;
    } catch (e) {
        isAddress = false;
    }
    return isAddress;
}