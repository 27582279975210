import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import bgTile from '../image/tile_potato.png';
import { color, validateAddress, WIDTH_MOBILE } from '../util';
import { Link } from 'react-router-dom';
import FooterImg from '../image/footer.png';
import queryString from 'query-string';
import Back from '../image/back.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export function Whitelist() {
    const [addrError, setAddrErr] = useState<boolean|string>(false);
    const [referral, setReferral] = useState<boolean|string>(false);
    const [addr, setAddr] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [urlRef, setUrlRef] = useState<string|any>(null);

    useEffect(() => {
        const docHashRm = document.location.hash.replace('#whitelist', '');
        const qs = queryString.parse(docHashRm);
        
        if (qs?.ref && validateAddress(qs?.ref)) {
            setUrlRef(qs?.ref);
        }

        if (document && typeof document !== 'undefined') {
            ((d, s, id) => {
              const pjs = d.getElementsByTagName(s)[d.getElementsByTagName(s).length - 1];
              if (d.getElementById(id)) return;
              const js = d.createElement(s);
              js.id = id;
              // @ts-ignore
              js.src = '//platform.twitter.com/widgets.js';
              // @ts-ignore
              pjs.parentNode.insertBefore(js, pjs);
            })(document, 'script', 'twitter-js');

            // @ts-ignore
            if (window?.twttr?.widgets) {
                // @ts-ignore
                twttr.widgets.load();
            }
          }
    }, []);

    function handleResponse(data: any) {
        if (!data) {
            console.log('No response:', data);
            return;
        }
        setIsLoading(false);

        if (data?.err) {
            setAddrErr(!!data?.msg ? data?.msg : 'Something went wrong, please let us know on Twitter or Discord!')
            return;
        }
        if (data?.msg) {
            setReferral(`https://www.papertato.com/#whitelist?ref=${addr}`);
        }
    }

    async function validateAndSubmit(event: React.FormEvent) {
        event.preventDefault();
        const isValid = validateAddress(addr);
        setAddrErr(isValid ? false : 'Address is not valid!');

        let referralObj = !!urlRef ? {
            referral: urlRef
        } : {};
        const payload = Object.assign(
            {}, 
            { address: addr }, 
            referralObj
        )

        if (isValid) {
            setIsLoading(true);

            fetch('https://api.papertato.com/whitelist', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            })
                .then((response) => {
                    if (response) {
                        return response.json()
                    } else {
                        console.error('Something went wrong');
                        console.log(response);
                    }
                })
                .then(data => handleResponse(data));
        }
    }
    

    return(
        <StyledWhitelist>
            <Header>
                <div className="left">
                    <img src={FooterImg} alt="PaperTato Footer" />
                    <h3>Whitelist</h3>
                </div>
                <div className="right">
                    <Link to="/">
                        <img src={Back} alt="Back to PaperTato Home" />
                        Home 
                    </Link>
                </div>
            </Header>
            <Content>
                <StyledForm>
                    <div className="description">
                        <h3>Whitelist Steps</h3>
                        <ol>
                            <li>
                                <a
                                    href={`https://twitter.com/papertato`}
                                    className="twitter-follow-button"
                                    data-size='large'
                                    data-show-screen-name='true'
                                    data-show-count='false'
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    Follow @papertato on Twitter 
                                </a>
                            </li>
                            <li>
                                <a className="social" href="https://discord.gg/2Z75YRxH4H" target="_blank" rel="noreferrer">
                                    Join Discord (Optional)
                                </a>
                            </li>
                            <li>
                                Submit your Ethereum address below
                                <div className="rules">
                                    Each whitelisted address is eligible to mint <span>1 Free PaperTato NFT</span>. 
                                    Please do not submit multiple addresses or attempt a sybil attack. The PaperTato Team reserves the right to remove addresses from the whitelist.
                                </div>
                            </li>
                            <li>
                                Share your referral link! 
                                <div className="rules">
                                    You can mint <span>1 extra PaperTato per referral</span>, up to <span>maximum of 5</span> PaperTatos. Submit your address to receive a referral link.
                                </div>
                            </li>
                        </ol>
                    </div>
                    <div className="relpos">
                        {
                            !referral && (<>
                                <div className="input-wrapper">
                                    <div className="label">
                                        ETH Address
                                    </div>
                                    <input onChange={(e) => setAddr(e.target.value)} placeholder='0x0000000000000000000000000000000000000000' />
                                    {addrError && 
                                        <div className="error">{addrError}</div>
                                    }
                                </div>
                                <input type="submit" className="submit" value="Submit" onClick={(e) => validateAndSubmit(e)} />

                                {isLoading &&
                                    <div className="loader-form">
                                        <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                        width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xmlSpace="preserve">
                                            <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                                                s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                                                c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
                                            <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                                                C22.32,8.481,24.301,9.057,26.013,10.047z">
                                                <animateTransform attributeType="xml"
                                                attributeName="transform"
                                                type="rotate"
                                                from="0 20 20"
                                                to="360 20 20"
                                                dur="0.5s"
                                                repeatCount="indefinite"/>
                                            </path>
                                        </svg>
                                    </div>
                                }
                            </>)
                        }
                        {
                            !!referral && (
                                <div className="success">
                                    <span className="green">Success!</span>
                                    <p>
                                        Use this referral URL for extra mints:<br />
                                        <input value={referral.toString()} onKeyPress={(e) => {e.preventDefault(); e.stopPropagation();}} /><br />
                                        <CopyToClipboard text={referral.toString()} onCopy={() => setIsCopied(true)}>
                                            <button className={isCopied ? 'copied' : ''} onClick={(e) => {e.preventDefault(); e.stopPropagation();}}>
                                                {
                                                    !!isCopied && 
                                                    <>Copied!</>
                                                }
                                                {
                                                    !isCopied && 
                                                    <>Copy to clipboard</>
                                                }
                                            </button>
                                        </CopyToClipboard>
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </StyledForm>
            </Content>
            <Footer>
                <p>&#169; PaperTato {new Date().getFullYear()}. All Rights Reserved.</p>
            </Footer>
        </StyledWhitelist>
    );
}


const StyledForm = styled.form`
    max-width: 500px;
    margin: 50px 15px;
    padding: 20px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.75);
    color: ${color.brownDark};

    .success {
        margin: 50px auto 40px;
        text-align: center;

        span {
            font-weight: 900;
            font-size: 30px;
            &.green {
                color: ${color.green};
            }
        }

        input {
            width: 90%;
            width: calc(100% - 40px);
            padding: 8px 9px;
            border-radius: 10px;
            border: 2px solid ${color.green};
            color: ${color.brownDark};
            margin: 4px auto 10px;
        }

        button {
            -webkit-appearance: none;
            padding: 8px 12px;
            color: black;
            cursor: pointer;
            border: 2px solid black;
            border-radius: 5px;
            min-width: 134px;
            background: ${color.offWhiteDark};

            &:hover {
                background: ${color.offWhite};
            }

            &.copied {
                background: ${color.green};

                &:hover {
                    background: ${color.green};
                }
            }
        }
    }

    .relpos {
        position: relative;
    }

    .loader-form {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.75);
        border-radius: 15px;
    }

    .description {
        h3 {
            text-align: center;
        }

        a.social {
            color: #5865f2;
        }

        ol {
            li {
                margin-bottom: 15px;
                font-size: 17px;

                .rules {
                    font-size: 13px;

                    span {
                        color: ${color.red};
                    }
                }
            }
        }
    }

    .input-wrapper {
        text-align: center;
        margin: 50px auto 0;
        width: 360px;

        input {
            width: 90%;
            width: calc(100% - 40px);
            padding: 8px 9px;
            border-radius: 10px;
            border: 2px solid ${color.green};

            &::placeholder {
                color: #b98c6c;
            }
        }

        @media screen and (max-width: ${WIDTH_MOBILE}) {
            width: 300px;
        }
    }

    .error {
        color: ${color.red};
        margin-top: 6px;
    }

    .submit {
        -webkit-appearance: none;
        color: black;
        display: block;
        text-align: center;
        margin: 15px auto 30px;
        padding: 8px 12px;
        cursor: pointer;
        border: 2px solid black;
        border-radius: 5px;
        background: ${color.offWhiteDark};

        &:hover {
            background: ${color.offWhite};
        }
    }

    .label {
        font-weight: 900;
        margin-bottom: 10px;
    }

    .twitter-follow-button-rendered {
        margin-bottom: -8px;
    }
`;

const StyledWhitelist = styled.div`
    background-color: ${color.offWhite};
    background-image: url(${bgTile});
    background-repeat: repeat;
    background-size: 128px 128px;
    background-position: 0 60px;
    height: 100%;
    min-height: 100%;
`;

const Content = styled.div`
    min-height: 80%;
    min-height: calc(100% - 140px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Header = styled.div`
    width: 100%;
    height: 80px;
    background: ${color.brownDark};
    color: ${color.offWhite};
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .left {
        img {
            max-height: 34px;
            margin-top: -5px;
            margin-left: 10px;
            padding-right: 2px;
        }
    }
    .right {
        margin-right: 14px;
        img {
            max-height: 14px;
            margin-right: 5px;
        }
        a {
            text-decoration: none;
            color: ${color.offWhiteDark};
            font-weight: 900;
            text-transform: uppercase;
            margin-top: 2px;
        }
    }
`;

const Footer = styled.div`
    width: 100%;
    height: 60px;
    font-size: 14px;
    text-align: center;
    background: ${color.brownDark};
    color: ${color.offWhite};
    display: flex;
    justify-content: center;
    align-items: center;
`;