import React, { Suspense, useState } from 'react';
import { ethers } from "ethers";
import { Web3ReactProvider } from '@web3-react/core';
import { ThemeProvider, Cursor } from '@react95/core';
import styled from 'styled-components';
import { HashRouter, Switch, Route } from 'react-router-dom';
import '@react95/icons/icons.css';
import wallpaper from './image/3d-base.png';
import { HomePage, NormalSite, Whitelist } from './page';
import Logo from './image/logo.png';


function App() {
  const [isOs, setIsOs] = useState(false);

  // @ts-ignore
  function getLibrary(provider, connector) {
    const library = new ethers.providers.Web3Provider(provider)
    library.pollingInterval = 12000
    return library
  }

  return (
    <Suspense fallback={
      <div className="loader">
        <h2>Loading...</h2>
        <img src={Logo} alt="loading" />
      </div>
    }>
      <Web3ReactProvider getLibrary={getLibrary}>
        <HashRouter hashType='noslash'>
          <Switch>
            <Route path="/whitelist">
              <Whitelist />
            </Route>
            <Route path="*">
              {!isOs && <NormalSite setIsOs={setIsOs} />}
              {isOs && (
                <StyledOs className="os">
                  <ThemeProvider theme="maple">
                    <AppStyleOverrides>
                      <HomePage setIsOs={setIsOs} />
                    </AppStyleOverrides>
                  </ThemeProvider>
                </StyledOs>
              )}
            </Route>
          </Switch>
        </HashRouter>
      </Web3ReactProvider>
    </Suspense>
  );
}

const StyledOs = styled.div`
  width: 100%;
  height: 100%;
`;

const AppStyleOverrides = styled.div`
  background: url(${wallpaper});
  background-repeat: repeat;
  background-size: cover !important;
  background-position: center;
  min-height: 100%;
  width: 100%;
  ${Cursor.Auto};

  button, button:hover, label, label:hover, input {
    ${Cursor.Auto};
  }
`;


export default App;
