import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Frame, Fieldset, RadioButton, Button } from '@react95/core';
import { 
    Pbrush1
} from '@react95/icons';


type ModalType = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalMint({
    setShow
}: ModalType) {
    const [mintCount, setMintCount] = useState(1);
    const [plural, setPlural] = useState('');
    const priceEth = 0.05;

    useEffect(() => {
        if (mintCount > 1) {
            setPlural('es');
        } else {
            setPlural('');
        }
    }, [mintCount]);

    return (<>
        <Modal
            icon={<Pbrush1 variant="32x32_4" />}
            title="Mint.exe"
            closeModal={() => setShow(false)}
            width="300"
            height="400"
            defaultPosition={{
                x: 40,
                y: 50,
            }}
        >
            <Frame width="288" height="100%" padding={4} boxShadow="in">
                <StyledFieldContainer>
                    <Fieldset legend="Potato Count" style={{ width: '250px' }}>
                        <RadioButtonList>
                            <RadioButton
                                name="working"
                                value="1"
                                checked={mintCount === 1}
                                onChange={() => setMintCount(1)}
                            >
                                🥔
                            </RadioButton>
                            <RadioButton
                                name="working"
                                value="2"
                                checked={mintCount === 2}
                                onChange={() => setMintCount(2)}
                            >
                                🥔🥔
                            </RadioButton>
                            <RadioButton
                                name="working"
                                value="3"
                                checked={mintCount === 3}
                                onChange={() => setMintCount(3)}
                            >
                                🥔🥔🥔
                            </RadioButton>
                            <RadioButton
                                name="working"
                                value="4"
                                checked={mintCount === 4}
                                onChange={() => setMintCount(4)}
                            >
                                🥔🥔🥔🥔
                            </RadioButton>
                            <RadioButton
                                name="working"
                                value="5"
                                checked={mintCount === 5}
                                onChange={() => setMintCount(5)}
                            >
                                🥔🥔🥔🥔🥔
                            </RadioButton>
                            <RadioButton
                                name="working"
                                value="6"
                                checked={mintCount === 6}
                                onChange={() => setMintCount(6)}
                            >
                                🥔🥔🥔🥔🥔🥔
                            </RadioButton>
                            <RadioButton
                                name="working"
                                value="7"
                                checked={mintCount === 7}
                                onChange={() => setMintCount(7)}
                            >
                                🥔🥔🥔🥔🥔🥔🥔
                            </RadioButton>
                            <RadioButton
                                name="working"
                                value="8"
                                checked={mintCount === 8}
                                onChange={() => setMintCount(8)}
                            >
                                🥔🥔🥔🥔🥔🥔🥔🥔
                            </RadioButton>
                            <RadioButton
                                name="working"
                                value="9"
                                checked={mintCount === 9}
                                onChange={() => setMintCount(9)}
                            >
                                🥔🥔🥔🥔🥔🥔🥔🥔🥔
                            </RadioButton>
                            <RadioButton
                                name="working"
                                value="10"
                                checked={mintCount === 10}
                                onChange={() => setMintCount(10)}
                            >
                                🥔🥔🥔🥔🥔🥔🥔🥔🥔🥔
                            </RadioButton>
                        </RadioButtonList>
                    </Fieldset>
                </StyledFieldContainer>

                <StyledBottomRow>
                    <StyledFieldContainer className="eth child">
                        <Fieldset legend="ETH" style={{ width: '116px', textAlign: 'center' }}>
                            {(priceEth * mintCount).toFixed(2)}
                        </Fieldset>
                    </StyledFieldContainer>

                    <StyledButton className="child">
                        <Button>Mint Potato{plural}</Button>
                    </StyledButton>
                </StyledBottomRow>
            </Frame>
        </Modal>
    </>);
}

const StyledButton = styled.div`
    height: 40px;
    width: 105px;
    margin-top: 11px;
    button {
        height: 40px;
        width: 105px;
    }
`;

const StyledFieldContainer = styled.div`
    margin-top: 5px;

    &.eth {
        margin-top: 6px;
        margin-right: 11px;
    }
`;

const StyledBottomRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const RadioButtonList = styled.div`
  display: flex;
  flex-direction: column;
`;