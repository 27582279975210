import React from 'react';
import { ResponsiveTreeMap } from '@nivo/treemap';
import { itmChance } from '../util/chance';

export function RarityPage() {
    const colors = {
        common: '#ffffff',
        uncommon: '#1eff00',
        rare: '#A335EE',
        epic: '#FF8000',
        legendary: '#E6CC80'
    };

    const data = {
        name: 'PaperTato Traits',
        color: '#73c7e2',
        children: Object.keys(itmChance).map((rarityKey) => {
            return {
                name: rarityKey,
                // @ts-ignore
                color: colors[rarityKey],
                // @ts-ignore
                children: itmChance[rarityKey].map((trait) => {
                    return {
                        name: trait.name,
                        // @ts-ignore
                        color: colors[rarityKey],
                        loc: trait.chance * 1
                    }
                })
            }
        }).reverse()
    };

    return (
        <ResponsiveTreeMap
            data={data}
            identity="name"
            value="loc"
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            tile="binary"
            leavesOnly={true}
            colors={{ scheme: 'set1' }}
            label=""
            nodeOpacity={0.6}
            tooltip={({ node }) => (
                <strong style={{ color: node.color, background: 'white', padding: '8px', borderRadius: '3px' }}>
                    {node.pathComponents[2]} / {node.pathComponents[1]}
                </strong>
            )}
        />
    );
}