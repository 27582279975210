import React, { useState } from 'react';
import { Modal, Frame, TextArea, Tabs, Tab } from '@react95/core';
import styled from 'styled-components';
import { 
    HelpBook,
} from '@react95/icons';


type ModalType = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalPotatoLore({
    setShow
}: ModalType) {
    let x = 235;

    if (window?.innerWidth < 580) {
        x = 2;
    } else {
        x = 235;
    }

    const [text, setValue] = useState("It used to be a simpler time for potatoes. Their lives were spent in cool, dark rooms where they waited patiently to fulfill their ultimate purpose: to become a delicious helping of hash browns or an order of cheese fries. It was the life every potato wanted -- the life they deserved. \n\nBut then a *certain* wild-eyed tech billionaire spent an afternoon at a seventh grade science fair and found out that a potato can also act as a battery. That night, the billionaire tweeted that he alone had invented potato battery technology and that spuds were the fuel of the future. The billionaire’s company stock went through the roof, and immediately his most loyal followers, the Apes, began hunting these innocent potatoes. They raided root cellars by the thousands. Soon enough, millions of potatoes were kidnapped and torn from their families, ruining the spuds’ dreams of one day becoming a nice soup. \n\nNow, no dark pantry floor is safe, and potatoes have been forced to hide in plain sight. They have gathered whatever disguises they can find, but sadly the Spirit Halloween mall kiosk has a very limited selection. By day they wander the streets among us, but by night they retreat to their brown paper bag camps, hoping to rise to their ultimate calling of being baked and loaded before they develop too many eyes.");
    const [tokenInfo, setTokenInfo] = useState("=== Super Secret Note === \n\nRarity tooling will be supported post-drop. Some PaperTatos are custom anomalies, possessing one-of-a-kind traits.");
    const [team, setTeam] = useState('The PaperTato team is mostly potatoes (what did you expect?), but it\'s pretty likely that there are also NFT Designers and Internet Janitors somewhere in the mix.');

    return (
        <Modal
            icon={<HelpBook variant="32x32_4" />}
            title="About"
            closeModal={() => setShow(false)}
            width="384"
            height="540"
            defaultPosition={{
                x,
                y: -10,
            }}
            buttons={[
                { value: 'OK', onClick: () => setShow(false) },
            ]}
        >
            <Wrap>
                <Tabs style={{ width: 350 }} defaultActiveTab="Lore">
                    <Tab title="Lore">
                        {/*@ts-ignore*/}
                        <TextArea
                            className="textarea"
                            rows={25}
                            cols={40}
                            value={text}
                            onChange={({
                                target: { value },
                            }: React.ChangeEvent<HTMLTextAreaElement>) => setValue(value)}
                        />
                    </Tab>
                    <Tab title="Business Potato">
                        {/*@ts-ignore*/}
                        <TextArea
                            className="textarea"
                            rows={25}
                            cols={40}
                            value={tokenInfo}
                            onChange={({
                                target: { value },
                            }: React.ChangeEvent<HTMLTextAreaElement>) => setTokenInfo(value)}
                        />
                    </Tab>
                    <Tab title="Team">
                        {/*@ts-ignore*/}
                        <TextArea
                            className="textarea"
                            rows={25}
                            cols={40}
                            value={team}
                            onChange={({
                                target: { value },
                            }: React.ChangeEvent<HTMLTextAreaElement>) => setTeam(value)}
                        />
                    </Tab>
                </Tabs>
            </Wrap>
        </Modal>
    )
}

const Wrap = styled.div`
    .textarea {
        width: 345px;
        height: 408px;
    }
`;