import React, { useEffect, useState } from 'react';
import { 
    // injected, 
    Desktop, 
    Taskbar 
} from '../component';
// import { useWeb3React } from '@web3-react/core';
import { 
    ModalWww,
    ModalUnderConstruction,
    ModalConnectionFail,
    ModalMint,
    ModalPotatoLore,
    ModalPotatoPreview,
    ModalPotatoNudes
} from '../component';


// traits - How rare is my papertato?
// lore
// team 
// button to mint - throw up a screen that says minting time to be 
// determined, follow on twitter / join on discord for announcements

type OsChangeType = {
    readonly setIsOs: any;
}

export function HomePage({
    setIsOs
}: OsChangeType) {
    // const { activate } = useWeb3React();

    // const [activateAttempted, setActivateAttempted] = useState(false);

    const [showConnectionFail, setShowConnectionFail] = useState(false);
    const [showMint, setShowMint] = useState(false);
    const [showPotatoLore, setShowPotatoLore] = useState(false);
    const [showPotatoPreview, setShowPotatoPreview] = useState(false);
    const [showPotatoNudes, setShowPotatoNudes] = useState(false);
    const [showUnderConstruction, setShowUnderConstruction] = useState(false);
    const [showWww, setShowWww] = useState(false);

    // useEffect(() => {
        
    // }, [showMint]);

    // useEffect(() => {
        
    // }, [showWww]);

    // useEffect(() => {
        
    // }, [showConnectionFail]);

    // useEffect(() => {
        
    // }, [showPotatoLore]);

    // useEffect(() => {
        
    // }, [showPotatoPreview]);

    // useEffect(() => {
        
    // }, [showPotatoNudes]);

    // useEffect(() => {
        
    // }, [showUnderConstruction]);

    // see if user has already been authorized, if so then activate
    useEffect(() => {
        // injected.isAuthorized().then((isAuthorized: boolean) => {
        //     if (isAuthorized) {
        //         activate(injected, undefined, true).catch(() => {
        //             setActivateAttempted(true);
        //         })
        //     } else {
        //         setActivateAttempted(true);
        //     }
        // });

        setShowPotatoLore(true);
        setShowPotatoPreview(true);
    }, []);

    return (
        <div className="w95-font">
            <Desktop
                setIsOs={setIsOs}
                setShowConnectionFail={setShowConnectionFail}
                setShowMint={setShowMint}
                setShowPotatoLore={setShowPotatoLore}
                setShowPotatoPreview={setShowPotatoPreview}
                setShowUnderConstruction={setShowUnderConstruction}
                setShowWww={setShowWww}
                setShowPotatoNudes={setShowPotatoNudes}
            />
            <Taskbar 
                setIsOs={setIsOs}
                setShowConnectionFail={setShowConnectionFail}
                setShowMint={setShowMint}
                setShowPotatoLore={setShowPotatoLore}
                setShowPotatoPreview={setShowPotatoPreview}
                setShowUnderConstruction={setShowUnderConstruction}
                setShowWww={setShowWww}
                setShowPotatoNudes={setShowPotatoNudes}
            />

            
            {showWww && <ModalWww setShow={setShowWww} />}
            {showMint && <ModalMint setShow={setShowMint} />}
            {showPotatoLore && <ModalPotatoLore setShow={setShowPotatoLore} />}
            {showPotatoPreview && <ModalPotatoPreview setShow={setShowPotatoPreview} />}
            {showConnectionFail && <ModalConnectionFail setShow={setShowConnectionFail} />}
            {showUnderConstruction && <ModalUnderConstruction setShow={setShowUnderConstruction} />}
            {showPotatoNudes && <ModalPotatoNudes setShow={setShowPotatoNudes} />}
        </div>
    );
}