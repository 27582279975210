import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import './index.css';

if (process.env.REACT_APP_ENABLE_SENTRY) {
  Sentry.init({
    dsn: 'https://7d401d751c3948c19f313384f9319337@o336313.ingest.sentry.io/5428976',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
} 

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

