// iframe to the site
import React from 'react';
import { nanoid } from 'nanoid';
import { Modal, Frame } from '@react95/core';
import { 
    Msnstart120
} from '@react95/icons';

type ModalType = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalWww({
    setShow
}: ModalType) {
    const param = nanoid();

    return (
        <Modal
            icon={<Msnstart120 variant="32x32_4" />}
            title="World Wide Web"
            closeModal={() => setShow(false)}
            width="800"
            height="700"
            defaultPosition={{
                x: 40,
                y: 0,
            }}
        >
            <Frame width="600" height="100%" padding={4}>
                <iframe src={`https://www.papertato.com/?id=${param}`} width="778" height="100%">Loading...</iframe>
            </Frame>
        </Modal>
    );
}