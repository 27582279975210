import React, { useRef, Suspense } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, Stage, Environment, Html } from '@react-three/drei';
import { EffectComposer, Vignette } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import { isMobile } from 'react-device-detect';

export function HeroCanvas(props: any) {
    const tatoRef = useRef(null);
    const grassRef = useRef(null);
    // @ts-ignore
    const { nodes, materials } = useGLTF('/hero.glb');

    function CameraAdjust() {
        // This one makes the camera move in and out
        useFrame(({ clock, camera, mouse }) => {
            // @ts-ignore
            tatoRef.current.position.z = (Math.sin(clock.getElapsedTime() * 2) * 0.005);
            if (isMobile) {
                // @ts-ignore 
                // tatoRef.current.position.x = 0.01;
                // @ts-ignore
                tatoRef.current.rotation.x = 0.5 * -20 * Math.PI / 180;
                // @ts-ignore
                tatoRef.current.rotation.z = 0.5 * -30 * Math.PI / 180;
                return;
            }

            // @ts-ignore
            tatoRef.current.rotation.x = mouse.y * -20 * Math.PI / 180;
            // @ts-ignore
            tatoRef.current.rotation.z = mouse.x * -30 * Math.PI / 180;

            // @ts-ignore 
            grassRef.current.position.x = THREE.MathUtils.lerp(
                // @ts-ignore
                grassRef.current.position.x,
                mouse.x * -0.1,
                0.3
            );

            camera.position.x = THREE.MathUtils.lerp(camera.position.x, mouse.x * 0.09, 0.1);
            camera.rotation.x = THREE.MathUtils.lerp(camera.rotation.x, ((mouse.y * 0.03) + (11 * Math.PI / 180)), 0.1);
        })
        return null
    }

    return (
        <Canvas 
            shadows 
            dpr={[1, 2]} 
            camera={{fov: 50, position: [0, 0.5, !isMobile ? 2 : 3], rotation: [11 * Math.PI / 180, 0, 0]}}
        >
            <Suspense fallback={
                <Html>
                    <div className="loader inner">
                        <h2>Still Loading...</h2>
                    </div>
                </Html>
            }>
                {/* @ts-ignore */}
                <Stage adjustCamera={false} preset="rembrandt" intensity={0}  environment={null}>
                    <Environment background={false} files={'potsdamer_platz_1k.hdr'} path={'/'} />
                    <ambientLight />
                    <CameraAdjust />
                    <group {...props} dispose={null} position={[0, 0, 0]}>
                        <group position={[-1.51, 0, -0.88]}>
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes['cloud-1'].geometry}
                                material={nodes['cloud-1'].material}
                                rotation={[Math.PI / 2, 0, 0]}
                                scale={[11.09, 8, 11.09]}>
                                <mesh
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.cloud_inner.geometry}
                                    material={nodes.cloud_inner.material}
                                >
                                    <meshPhongMaterial color={0xffffff} />
                                </mesh>
                            </mesh>
                            <pointLight position={[-0.1, 3, 0.01]} />
                        </group>
                        <group ref={grassRef}>
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes.Grass.geometry}
                                material={nodes.Grass.material}
                                position={[-5.02, 0, -0.88]}
                                rotation={[Math.PI / 2, 0, 0]}
                                scale={[11.09, 8, 11.09]}>
                                <meshPhongMaterial color={0x00b856} />
                                <mesh
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.Grass001.geometry}
                                    material={nodes.Grass001.material}
                                    position={[0.23, 0, 0]}
                                >
                                    <meshPhongMaterial color={0x00b856} />
                                </mesh>
                                <mesh
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.grass_plane.geometry}
                                    material={materials['Material.017']}
                                    position={[0.44, 0.133, -0.005]}
                                    rotation={[-Math.PI / 2, 0, 0]}
                                    scale={[0.6, 0.09, 0.12]}
                                    roughness={1.0}
                                >
                                    <meshPhongMaterial color={0x00b856} />
                                </mesh>
                            </mesh>
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes.Grass002.geometry}
                                material={nodes.Grass002.material}
                                position={[-0.28, 0, -0.88]}
                                rotation={[Math.PI / 2, 0, 0]}
                                scale={[11.09, 8, 11.09]}>
                                <meshPhongMaterial color={0x00b856} />
                                <mesh
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.Grass003.geometry}
                                    material={nodes.Grass003.material}
                                    position={[0.23, 0, 0]}
                                >
                                    <meshPhongMaterial color={0x00b856} />
                                </mesh>
                            </mesh>
                        </group>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes['background-sky'].geometry}
                            material={materials['Material.012']}
                            position={[0, 0.01, 0]}
                            scale={[20, 4.16, 4.16]}
                        >
                            <meshPhongMaterial color={0x17f0fd} />
                        </mesh>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes['cloud-2'].geometry}
                            material={nodes['cloud-2'].material}
                            position={[1.12, 0, -0.88]}
                            rotation={[1.57, -0.16, Math.PI]}
                            scale={[9.26, 6.66, 9.41]}>
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes.cloud_inner001.geometry}
                                material={nodes.cloud_inner001.material}
                            >
                                <meshPhongMaterial color={0xffffff} />
                            </mesh>
                        </mesh>
                        <group 
                            rotation={[1.59, 0.01, -0.01]}
                            position={[0, 0.88, 0.01]}
                            scale={[7.94, 7.94, 7.94]}
                        >
                            <mesh
                                ref={tatoRef}
                                castShadow
                                receiveShadow
                                geometry={nodes.Curve.geometry}
                                material={nodes.Curve.material}
                                >
                                <mesh
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.brown.geometry}
                                    material={materials['SVGMat.039']}
                                    position={[0.0034, 0, -0.0092]}
                                />
                            </mesh>
                        </group>
                    </group>
                </Stage>
            </Suspense>
            <EffectComposer>
                <Vignette
                    offset={0.5}
                    darkness={0.5}
                    // Eskil's vignette technique works from the outside inwards rather
                    // than the inside outwards, so if this is 'true' set the offset
                    // to a value greater than 1.
                    // See frag for details - https://github.com/vanruesc/postprocessing/blob/main/src/effects/glsl/vignette/shader.frag
                    eskil={false}
                    blendFunction={BlendFunction.NORMAL}
                />
            </EffectComposer>
        </Canvas>
    )
}

useGLTF.preload('/hero.glb')