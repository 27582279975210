import React from 'react';
import { Modal, Frame } from '@react95/core';
import { 
    Warning
} from '@react95/icons';

type ModalType = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalConnectionFail({
    setShow
}: ModalType) {
    return (
        <Modal
            icon={<Warning variant="32x32_4" />}
            title="Metamask Connection Failed"
            closeModal={() => setShow(false)}
            width="300"
            height="300"
            defaultPosition={{
                x: 30,
                y: 10,
            }}
            buttons={[
                { value: 'Ok', onClick: () => setShow(false) },
            ]}
        >
            <Frame width={300 - 20} height="100%" padding={4}>
                <Frame height="100%" boxShadow="in" bg="#0827F5">
                    Connection failed, please refresh and try again
                </Frame>
            </Frame>
        </Modal>
    );
}