export const itmChance = {
  "common": [
    {
      "name": "Padded Room",
      "chance": "9.39"
    },
    {
      "name": "Vote for Potato",
      "chance": "9.99"
    },
    {
      "name": "Sports",
      "chance": "10.44"
    },
    {
      "name": "I Potato NY",
      "chance": "10.50"
    },
    {
      "name": "Brown Cowboy",
      "chance": "10.70"
    },
    {
      "name": "Red Shorts",
      "chance": "10.84"
    },
    {
      "name": "Flippers",
      "chance": "10.86"
    },
    {
      "name": "Red Pill",
      "chance": "11.50"
    },
    {
      "name": "Chaps",
      "chance": "12.09"
    },
    {
      "name": "Superhero Briefs",
      "chance": "12.64"
    },
    {
      "name": "Blue Witch",
      "chance": "13.63"
    },
    {
      "name": "Blue Puffy Sleeves",
      "chance": "13.69"
    },
    {
      "name": "Solid Grey",
      "chance": "13.70"
    },
    {
      "name": "Kilt",
      "chance": "13.99"
    },
    {
      "name": "Santa Suit",
      "chance": "14.04"
    },
    {
      "name": "Hot Chip",
      "chance": "14.07"
    },
    {
      "name": "Santa Hat",
      "chance": "14.17"
    },
    {
      "name": "Nauseous Guy",
      "chance": "14.23"
    },
    {
      "name": "Black Witch",
      "chance": "14.73"
    },
    {
      "name": "Gym Socks",
      "chance": "15.09"
    },
    {
      "name": "Tiny Smile",
      "chance": "15.66"
    },
    {
      "name": "Green Shorts",
      "chance": "17.16"
    },
    {
      "name": "Cat Eye Glasses",
      "chance": "17.89"
    },
    {
      "name": "White Briefs",
      "chance": "18.89"
    },
    {
      "name": "Solid Yellow",
      "chance": "20.13"
    },
    {
      "name": "Back By The Dumpster",
      "chance": "23.27"
    },
    {
      "name": "Orange Slides",
      "chance": "23.34"
    },
    {
      "name": "Burglar Mask",
      "chance": "23.71"
    },
    {
      "name": "Green Rubber Boots",
      "chance": "26.37"
    }
  ],
  "uncommon": [
    {
      "name": "Ham King Crown",
      "chance": "2.67"
    },
    {
      "name": "Green Zebra Pants",
      "chance": "2.70"
    },
    {
      "name": "Make Potato Potato Potato",
      "chance": "2.80"
    },
    {
      "name": "White Pocket Tee",
      "chance": "2.97"
    },
    {
      "name": "Jailhouse",
      "chance": "3.03"
    },
    {
      "name": "Timbys",
      "chance": "3.07"
    },
    {
      "name": "G Slides",
      "chance": "3.07"
    },
    {
      "name": "Sunglasses",
      "chance": "3.10"
    },
    {
      "name": "Proud Boys Shirt",
      "chance": "3.24"
    },
    {
      "name": "Princess Headwear",
      "chance": "3.24"
    },
    {
      "name": "Pink Tutu",
      "chance": "3.46"
    },
    {
      "name": "Black Heels",
      "chance": "3.47"
    },
    {
      "name": "Sad Clown",
      "chance": "3.53"
    },
    {
      "name": "Blue Shorts",
      "chance": "3.73"
    },
    {
      "name": "Cyclops",
      "chance": "3.74"
    },
    {
      "name": "Electric Helmet",
      "chance": "3.77"
    },
    {
      "name": "Potato Games",
      "chance": "3.79"
    },
    {
      "name": "Wolf Howling",
      "chance": "4.01"
    },
    {
      "name": "Business Tato",
      "chance": "4.06"
    },
    {
      "name": "Sneakers",
      "chance": "4.17"
    },
    {
      "name": "Kitty Cat",
      "chance": "4.20"
    },
    {
      "name": "Green Sharks",
      "chance": "4.31"
    },
    {
      "name": "Luxurious Pirate Felt",
      "chance": "4.60"
    },
    {
      "name": "Elf Ears",
      "chance": "5.00"
    },
    {
      "name": "Simulated Muscles",
      "chance": "5.19"
    },
    {
      "name": "Snowshoes",
      "chance": "5.79"
    },
    {
      "name": "Blue Sky Green Grass",
      "chance": "6.11"
    },
    {
      "name": "Gold Tooth Guy",
      "chance": "7.20"
    },
    {
      "name": "Weird Clown Hair",
      "chance": "7.43"
    }
  ],
  "rare": [
    {
      "name": "Purple Viral Halter",
      "chance": "1.13"
    },
    {
      "name": "Pug",
      "chance": "1.13"
    },
    {
      "name": "Blue Hot Dogs",
      "chance": "1.36"
    },
    {
      "name": "Blackout Features",
      "chance": "1.37"
    },
    {
      "name": "Blonde Wig",
      "chance": "1.47"
    },
    {
      "name": "Black Bikini Halter",
      "chance": "1.49"
    },
    {
      "name": "Orange Eggs",
      "chance": "1.54"
    },
    {
      "name": "Bake Bro",
      "chance": "1.79"
    },
    {
      "name": "Purple Bats",
      "chance": "1.91"
    },
    {
      "name": "Green Beanie",
      "chance": "2.11"
    },
    {
      "name": "Pink Knives",
      "chance": "2.24"
    },
    {
      "name": "Blue Mohawk",
      "chance": "2.36"
    },
    {
      "name": "Drag Makeup",
      "chance": "2.46"
    }
  ],
  "epic": [
    {
      "name": "Outrun",
      "chance": "0.31"
    },
    {
      "name": "Dino",
      "chance": "0.39"
    },
    {
      "name": "Pink Bunnies",
      "chance": "0.59"
    },
    {
      "name": "Bottom Censor Bar",
      "chance": "0.73"
    },
    {
      "name": "Top Censor Bar",
      "chance": "0.90"
    },
    {
      "name": "Cat",
      "chance": "0.94"
    },
    {
      "name": "Dog",
      "chance": "0.97"
    }
  ],
  "legendary": [
    {
      "name": "Cat and Dog",
      "chance": "0.01"
    },
    {
      "name": "Blue Striped Chameleon",
      "chance": "0.06"
    },
    {
      "name": "Tesluhh Shirt",
      "chance": "0.19"
    },
    {
      "name": "Orange Striped Chameleon",
      "chance": "0.20"
    },
    {
      "name": "Black Striped Chameleon",
      "chance": "0.24"
    }
  ]
};