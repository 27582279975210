import React from 'react';
import { Modal, Frame, Video } from '@react95/core';
import { 
    ReaderCd
} from '@react95/icons';
import videofile from '../image/nudes.mp4';
import nudesImg from '../image/nudes.png';


type ModalType = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalPotatoNudes({
    setShow
}: ModalType) {
    return (
        <Modal
            icon={<ReaderCd variant="32x32_4" />}
            title="Homework (D:)"
            closeModal={() => setShow(false)}
            width="300"
            height="448"
            defaultPosition={{
                x: 30,
                y: 50,
            }}
        >
            {/* @ts-ignore */}
            <Video width="300" src={videofile} name="Hot_potato_xxx.mp4" videoProps={{playsInline: true, poster: `${nudesImg}`, autoPlay: true, loop: true}} />
        </Modal>
    );
}