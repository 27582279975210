import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Frame } from '@react95/core';
import { Warning } from '@react95/icons';
import construction from '../image/construction.gif';

type ModalType = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

function randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function ModalUnderConstruction({
    setShow
}: ModalType) {
    const [x, setX] = useState(randomInteger(1, 20));
    const [y, setY] = useState(randomInteger(40, 100));

    useEffect(() => {
        setX(randomInteger(1, 20));
        setY(randomInteger(40, 100));
    }, [])

    return (
        <Modal
            icon={<Warning variant="32x32_4" />}
            title="Under Construction"
            closeModal={() => setShow(false)}
            width="300"
            height="300"
            defaultPosition={{
                x,
                y,
            }}
            buttons={[
                { value: 'Ok', onClick: () => setShow(false) },
            ]}
        >
            <Frame width={300 - 20} height="100%" padding={4}>
                <Frame height="100%" boxShadow="in" bg="#0827F5">
                    <StyledMessage>
                        <img src={construction} alt="construction" width="200" />
                    </StyledMessage>
                </Frame>
            </Frame>
        </Modal>
    );
}

const StyledMessage = styled.div`
    text-align: center;

    img {
        margin: 15px auto;
    }
`;