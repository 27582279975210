import React, { useState } from 'react';
import { TaskBar, List } from '@react95/core';
import { 
    ReaderClosed, 
    WindowsExplorer, 
    Computer3, 
    FolderExe2, 
    Pbrush1, 
    HelpBook, 
    Msnstart120, 
    Network3,
    Mmsys108, 
    Textchat2, 
    Camera, 
    ReaderCd,
    Access219
} from '@react95/icons';

type ModalsType = {
    setShowConnectionFail: React.Dispatch<React.SetStateAction<boolean>>;
    setShowMint: React.Dispatch<React.SetStateAction<boolean>>;
    setShowPotatoLore: React.Dispatch<React.SetStateAction<boolean>>;
    setShowPotatoPreview: React.Dispatch<React.SetStateAction<boolean>>;
    setShowPotatoNudes: React.Dispatch<React.SetStateAction<boolean>>;
    setShowUnderConstruction: React.Dispatch<React.SetStateAction<boolean>>;
    setShowWww: React.Dispatch<React.SetStateAction<boolean>>;
}
type OsChangeType = {
    readonly setIsOs: any;
}

export function Taskbar({
    setShowConnectionFail,
    setShowMint,
    setShowPotatoLore,
    setShowPotatoPreview,
    setShowPotatoNudes,
    setShowUnderConstruction,
    setShowWww,
    setIsOs
}: ModalsType & OsChangeType) {    
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return (
        <TaskBar
            list={
                <List>
                    <List.Item icon={<FolderExe2 variant="32x32_4" />}>
                        <List>
                            {/* <List.Item icon={<Pbrush1 variant="32x32_4" />} onClick={() => params?.dev == 'true' ? setShowMint(true) : setShowUnderConstruction(true)}>
                                Mint.exe
                            </List.Item> */}
                            <List.Item icon={<Access219 variant="32x32_4" />} onClick={() => setShowPotatoPreview(true)}>
                                Demo.exe
                            </List.Item>
                        </List>
                        Programs
                    </List.Item>

                    <List.Item icon={<Msnstart120 variant="32x32_4" />} onClick={() => { setShowWww(true); }}>
                        World Wide Web
                    </List.Item>

                    <List.Item icon={<Network3 variant="32x32_4" />}>
                        <List>
                            <a href="https://twitter.com/papertato" target="_blank" rel="noreferrer">
                                <List.Item icon={<Textchat2 variant="32x32_4" />}>
                                        Twitter.html
                                </List.Item>
                            </a>
                            <a href="https://discord.gg/2Z75YRxH4H" target="_blank" rel="noreferrer">
                                <List.Item icon={<Mmsys108 variant="32x32_4" />}>
                                    Discord.js
                                </List.Item>
                            </a>
                            <a href="https://www.instagram.com/makepotato/" target="_blank" rel="noreferrer">
                                <List.Item icon={<Camera variant="32x32_4" />}>
                                    Instagram.php
                                </List.Item>
                            </a>
                        </List>
                        Social
                    </List.Item>

                    <List.Item icon={<HelpBook variant="32x32_4" />} onClick={() => { setShowPotatoLore(true); }}>
                        About
                    </List.Item>

                    <List.Divider />
                    
                    <List.Item icon={<Computer3 variant="32x32_4" />} onClick={() => { setIsOs(false); }}>
                        Shut Down...
                    </List.Item>
                </List>
            }
        />
    );
      
}

