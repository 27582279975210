import React, { useEffect, useState } from 'react';
import { Modal, Frame } from '@react95/core';
import { 
    Access219
} from '@react95/icons';
import preview from '../image/preview.gif';


type ModalType = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ModalPotatoPreview({
    setShow
}: ModalType) {
    let x = 125;

    if (window?.innerWidth < 450) {
        x = 10;
    } else {
        x = 125;
    }

    return (
        <Modal
            icon={<Access219 variant="32x32_4" />}
            title="Demo.exe"
            closeModal={() => setShow(false)} 
            width="300"
            height="320"
            defaultPosition={{
                x,
                y: 50,
            }}
        >
            <Frame width="300" height="100%" padding={4}>
                <img src={preview} height="280" width="280" />
            </Frame> 
        </Modal>
    );
}