import React, { useState } from 'react';
import styled from 'styled-components';
import { Mmsys108, Pbrush1, Textchat2, Camera, ReaderCd, Computer3 } from '@react95/icons';
import { Cursor } from '@react95/core';

type ModalsType = {
    setShowConnectionFail: React.Dispatch<React.SetStateAction<boolean>>;
    setShowMint: React.Dispatch<React.SetStateAction<boolean>>;
    setShowPotatoLore: React.Dispatch<React.SetStateAction<boolean>>;
    setShowPotatoPreview: React.Dispatch<React.SetStateAction<boolean>>;
    setShowPotatoNudes: React.Dispatch<React.SetStateAction<boolean>>;
    setShowUnderConstruction: React.Dispatch<React.SetStateAction<boolean>>;
    setShowWww: React.Dispatch<React.SetStateAction<boolean>>;
}
type OsChangeType = {
    readonly setIsOs: any;
}

export function Desktop({
    setShowConnectionFail,
    setShowMint,
    setShowPotatoLore,
    setShowPotatoPreview,
    setShowPotatoNudes,
    setShowUnderConstruction,
    setShowWww,
    setIsOs
}: ModalsType & OsChangeType) {

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return (
        <>
            <StyledDesktop>
                {/* <StyledShorcut onClick={() => params?.dev == 'true' ? setShowMint(true) : setShowUnderConstruction(true)}>
                    <Pbrush1
                        className="pointer"
                        onClick={() => {}}
                        variant="32x32_4"
                    />
                    <div>Mint.exe</div>
                </StyledShorcut> */}
                <StyledShorcut onClick={() => setIsOs(false)}>
                    <Computer3
                        className="pointer"
                        onClick={() => {}}
                        variant="32x32_4"
                    />
                    <div>Shut Down...</div>
                </StyledShorcut>

                <StyledShorcut onClick={() => setShowPotatoNudes(true)}>
                    <ReaderCd
                        className="pointer"
                        onClick={() => {}}
                        variant="32x32_4"
                    />
                    <div>Homework (D:)</div>
                </StyledShorcut>

                <Spacer />

                <a href="https://twitter.com/papertato" target="_blank" rel="noreferrer">
                    <StyledShorcut>
                        <Textchat2
                            className="pointer"
                            onClick={() => {}}
                            variant="32x32_4"
                        />
                        <div>Twitter.html</div>
                    </StyledShorcut>
                </a>

                <a href="https://discord.gg/2Z75YRxH4H" target="_blank" rel="noreferrer">
                    <StyledShorcut>
                        <Mmsys108
                            className="pointer"
                            onClick={() => {}}
                            variant="32x32_4"
                        />
                        <div>Discord.js</div>
                    </StyledShorcut>
                </a>

                <a href="https://www.instagram.com/makepotato/" target="_blank" rel="noreferrer">
                    <StyledShorcut>
                        <Camera
                            className="pointer"
                            onClick={() => {}}
                            variant="32x32_4"
                        />
                        <div>Instagram.php</div>
                    </StyledShorcut>
                </a>
            </StyledDesktop>
        </>
    );
} 


const StyledDesktop = styled.div`
    position: absolute;
    top: 90px;
    left: 24px;
`;

const Spacer = styled.div`
    height: 70px;
    width: 10px;
`;

const StyledShorcut = styled.div`
    display: flex;
	align-items: center;
    flex-direction: column;
    ${Cursor.Pointer};
    padding-bottom: 4px;

    div {
        margin: 4px auto;
        text-align: center;
    }
`;